import { Reducer } from "redux";
import { LoginState, LoginTypes } from "./types";

export const initialState: LoginState = {
    loggedin: false,
    loading: false
};
const reducer: Reducer<LoginState> = (state = initialState, action) => {
    switch (action.type) {
        case LoginTypes.LOGOUT: {
            console.log('LOGOUT ');
            return {
                loggedin: false,
                user: undefined,
                loading: false,
                errors: undefined
            }
        }
        case LoginTypes.CLEAR_MESSAGE: {
            console.log('CLEAR_MESSAGE ');
            return {
                loggedin: false,
                user: undefined,
                loading: false,
                errors: undefined
            }
        }
        case LoginTypes.LOGIN_ERROR: {
            console.log('LOGIN_ERROR ', action.error);
            return {
                loggedin: false,
                user: undefined,
                loading: false,
                errors: action.error
            }
        }
        case LoginTypes.LOGIN: {
            console.log('LOGIN ');
            return {
                loggedin: true,
                user: action.payload,
                loading: false
            }
        }
        case LoginTypes.LOGIN_LOADING: {
            console.log('LOGIN LOADING');
            return {
                loggedin: false,
                loading: true
            }
        }
        case LoginTypes.AFTER_LOGIN: {
            console.log('LAFTER LOGING');
            return {
                loggedin: true,
                user: state.user,
                loading: false
            }
        }
        default: {
            return state;
        }
    }
};
export { reducer as LoginReducer };