import { CodigoBarra, RequestActualizaPiezaHub, ResponseActualizaPiezaHub, ResponseEventoExcepcion, ReposnseOrdenServicio, TokenBx } from "../models";
import axios from "axios";
import { codigoUsuario, tokenUsuario, urlBaseHub, urlToken, granttype, clientid, clientsecret, apikey } from "./const";

axios.interceptors.request.use(request => {
    console.log('Starting Request', request)
    return request
})

axios.interceptors.response.use(response => {
    console.log('Response:', response)
    return response
})

export type ResponseCubicar = {
    error: {
        codigo: number,
        mensaje: string
    }
}

export const doValidaCodigoBarra = (token: string, codigoBarraIn: string): Promise<CodigoBarra> =>
    new Promise((resolve) => {
        //axios.post(urlBaseHub + '/OperacionHubRest/BXHUB002_OperacionHub/validaCodigoBarra', { codigoBarra: codigoBarraIn }, {
        axios.post(urlBaseHub + '/validaCodigoBarra', { codigoBarra: codigoBarraIn }, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'apikey': apikey,
                'Content-Type': 'application/json',
                'codigoUsuario': codigoUsuario,
                'token': tokenUsuario
            }
        }).then((value) => {
            resolve(value.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });

export const doActualizaPiezaHub = (token: string, message: RequestActualizaPiezaHub): Promise<ResponseActualizaPiezaHub> =>
    new Promise((resolve) => {
        //axios.post(urlBaseHub + '/OperacionHubRest/BXHUB002_OperacionHub/actualizaPiezaHub',  message , {
        axios.post(urlBaseHub + '/actualizaPiezaHub', message, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'apikey': apikey,
                'Content-Type': 'application/json',
                'codigoUsuario': codigoUsuario,
                'token': tokenUsuario
            }
        }).then((value) => {
            resolve(value.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });

export const doObtienEventosExcepciones = (token: string): Promise<ResponseEventoExcepcion> =>
    new Promise((resolve) => {
        axios.get(urlBaseHub + '/obtieneEventosExcepciones', {
            headers: {
                'Authorization': 'Bearer ' + token,
                'apikey': apikey,
                'Content-Type': 'application/json',
                'codigoUsuario': codigoUsuario,
                'token': tokenUsuario
            }
        }).then((value) => {
            resolve(value.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });

export const doConsultaOrdenDeServicio = (token: string, ordenServicio: string): Promise<ReposnseOrdenServicio> =>
    new Promise((resolve) => {
        axios.get(urlBaseHub + '/consultaOrdenDeServicio/' + ordenServicio, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'apikey': apikey,
                'Content-Type': 'application/json',
                'codigoUsuario': codigoUsuario,
                'token': tokenUsuario
            }
        }).then((value) => {
            resolve(value.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });

export const doToken = (): Promise<TokenBx> =>
    new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append('grant_type', granttype);
        params.append('client_id', clientid);
        params.append('client_secret', clientsecret);
        axios.post(urlToken, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((value) => {
            resolve(value.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });