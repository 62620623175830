import { LoginEntity } from "../models";
import { codigoUsuario, tokenUsuario, urlBaseHub, urlToken, granttype, clientid, clientsecret, apikey } from "./const";
import axios from "axios";

axios.interceptors.request.use(request => {
  console.log('Starting Request', request)
  return request
})

axios.interceptors.response.use(response => {
  console.log('Response:', response)
  return response
})

export type LoggedUser = {
  error: {
    codigo: number,
    mensaje: string
  },
  usuario: {
    idSesion: number,
    usuario: string,
    nombre: string,
    codigoOficina: string,
    codigoPosta: string,
    codigoBodega: string,
    codigoEmpresa: string,
    tipoBodega: string,
  }
}

export type TokenBx = {
  access_token: string,
  expires_in: number,
  refresh_expires_in: number,
  refresh_token: string,
  token_type: string,
  'not-before-policy': number,
  session_state: string,
  scope: string
}

// Just a fake loginAPI
export const isValidLogin = (loginInfo: LoginEntity): Promise<boolean> =>
  new Promise((resolve) => {
    setTimeout(() => {
      // mock call
      resolve(loginInfo.login === "admin" && loginInfo.password === "test");
    }, 100000);
  });

export const loginUser = (token: string, message: LoginEntity): Promise<LoggedUser> =>
  new Promise((resolve) => {
    axios.post(urlBaseHub + '/login', {
      usuario: message.login,
      password: message.password
    }, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'codigoUsuario': codigoUsuario,
        'token': tokenUsuario,
        'apikey': apikey
      }
    }).then((value) => {
      resolve(value.data);
    }).catch((response) => {
      resolve(response.status);
    });
  });

export const doValidaUsuario = (token: string, usuario: string): Promise<LoggedUser> =>
  new Promise((resolve) => {
    axios.post(urlBaseHub + '/validaUsuario', { codigoUsuario: usuario }, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'apikey': apikey,
        'Content-Type': 'application/json',
        'codigoUsuario': codigoUsuario,
        'token': tokenUsuario
      }
    }).then((value) => {
      resolve(value.data);
    }).catch((response) => {
      resolve(response.status);
    });
  });

export const doToken = (): Promise<TokenBx> =>
  new Promise((resolve) => {
    const params = new URLSearchParams();
    params.append('grant_type', granttype);
    params.append('client_id', clientid);
    params.append('client_secret', clientsecret);
    axios.post(urlToken, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((value) => {
      resolve(value.data);
    }).catch((response) => {
      resolve(response.status);
    });
  });