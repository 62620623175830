import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, change } from 'redux-form';
import { Button, Col, FormGroup, Row, Container, Label } from 'reactstrap';
import ReduxInput from '../ReduxInput';
import ReduxOsFinder from '../ReduxOsFinder';
import { FaSave } from 'react-icons/fa';
import validate from './validate';
import { validaOS, actualizaPiezaHub, Inicializa } from '../../store/servicios/actions';
import '../../App.css';
import TableOS from './tableOS';
import { ApplicationState } from '../../store';
import { ServicioActionTypes } from '../../store/servicios/types';
import { RequestActualizaPiezaHub } from "../../models";
import SelectEvEx from '../SelectEvEx/SelectEvEx';
import CardHeader from "@material-ui/core/CardHeader";

interface Props { };

export const DigitacionHubPage: React.FC<Props & InjectedFormProps<{}, Props>> = (props: any) => {
  const { handleSubmit } = props;

  const handleKey = (event: any) => {
    var osIn = "";

    if (event.charCode === 0 && event.keyCode === 13) {
      if (props.store.getState().form.digitacionHubForm.values.evento !== "") {
        osIn = event.target.value.trim();
        props.dispatch(change('digitacionHubForm', 'os', osIn));
        event.target.select();
        if (osIn.length === 9 || osIn.length === 10 || osIn.length === 28) {
          props.dispatch(validaOS(props.store.getState().form.digitacionHubForm.values.os.toString()));
        } else {
          props.store.dispatch({
            type: ServicioActionTypes.FETCH_ERROR,
            mensaje: 'LARGO DE OS NO PERMITIDO'
          });
          document.getElementById("os")?.focus();
        }
      } else {
        props.store.dispatch({
          type: ServicioActionTypes.FETCH_ERROR,
          mensaje: 'NO HA SELECCIONADO UN EVENTO / EXCEPCIÓN'
        });
        document.getElementById("cboEvento")?.focus();
      }
    }
  }

  const handleClick = (event: any) => {
    var osIn = "";

    if (props.store.getState().form.digitacionHubForm.values.evento !== "") {
      if (!isNullUndefinedOrEmpty(props.store.getState().form.digitacionHubForm.values.os)) {
        osIn = props.store.getState().form.digitacionHubForm.values.os.toString().trim();
        props.dispatch(change('digitacionHubForm', 'os', osIn));
        if (osIn.length === 9 || osIn.length === 10 || osIn.length === 28) {
          props.dispatch(validaOS(props.store.getState().form.digitacionHubForm.values.os.toString()));
        } else {
          props.store.dispatch({
            type: ServicioActionTypes.FETCH_ERROR,
            mensaje: 'LARGO DE OS NO PERMITIDO'
          });
          document.getElementById("os")?.focus();
        }
      } else {
        props.store.dispatch({
          type: ServicioActionTypes.FETCH_ERROR,
          mensaje: 'NO SE HA INGRESADO OS'
        });
        document.getElementById("os")?.focus();
      }
    } else {
      props.store.dispatch({
        type: ServicioActionTypes.FETCH_ERROR,
        mensaje: 'NO HA SELECCIONADO UN EVENTO / EXCEPCIÓN'
      });
      document.getElementById("cboEvento")?.focus();
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    var eventoIn = [];
    if (e.target.value.trim() !== "") {
      eventoIn = e.target.value.trim().split("|");
      props.dispatch(change('digitacionHubForm', 'evento', eventoIn[0]));
      props.dispatch(change('digitacionHubForm', 'tipo', eventoIn[1]));
      props.dispatch(change('digitacionHubForm', 'observacion', ''));
      props.dispatch(change('digitacionHubForm', 'os', ''));
      props.dispatch(change('digitacionHubForm', 'cliente', ''));
      props.dispatch(change('digitacionHubForm', 'fechaPromesa', ''));
      props.dispatch(change('digitacionHubForm', 'osGraba', ''));
      props.dispatch(change('digitacionHubForm', 'piezas', '1'));
      props.store.getState().servicio.totalOS = 0;
      props.store.getState().servicio.totalPiezas = 0;
      props.store.getState().servicio.data.os = [];
      props.dispatch(Inicializa());
      document.getElementById("os")?.focus();
    }
  }

  const grabarButton = () => {
    if (props.store.getState().form.digitacionHubForm.values.evento !== "") {
      if (grabarOS()) {
        limpiarInput();
      }
    } else {
      props.store.dispatch({
        type: ServicioActionTypes.FETCH_ERROR,
        mensaje: 'NO HA SELECCIONADO UN EVENTO / EXCEPCIÓN'
      });
      document.getElementById("cboEvento")?.focus();
    }
  }

  const grabarOS = () => {
    if (props.store.getState().form.digitacionHubForm.values.osGraba !== undefined &&
      props.store.getState().servicio.loggedin &&
      isNumber(props.store.getState().form.digitacionHubForm.values.piezas)) {
      actualizaPieza();

      return true;
    } else {
      props.store.dispatch({
        type: ServicioActionTypes.FETCH_ERROR,
        mensaje: 'NO SE HA INGRESADO OS o PIEZAS'
      });
      document.getElementById("os")?.focus();
      return false;
    }
  }

  const isNumber = (value: any): boolean => {
    try {
      return ((value !== null) && (value !== '') && !isNaN(Number(value.toString())));
    } catch (Error) {
      console.log(Error);
      return false;
    }
  }

  const isNullUndefinedOrEmpty = (value: any): boolean => {
    var response = false;

    if (value === undefined)
      return true;
    if (value === null)
      return true;
    if (value === "")
      return true;

    return response;
  }

  const actualizaPieza = (): boolean => {
    var evento: string = '';
    var excepcion: string = '';

    if (props.store.getState().form.digitacionHubForm.values.tipo === 'EV') {
      evento = props.store.getState().form.digitacionHubForm.values.evento;
      excepcion = '';
    } else {
      evento = '';
      excepcion = props.store.getState().form.digitacionHubForm.values.evento;
    }

    const data: RequestActualizaPiezaHub = {
      datosPieza: {
        codigoBase: props.store.getState().login.user.usuario.codigoPosta,
        codigoBodega: props.store.getState().login.user.usuario.codigoBodega,
        tipoBodega: props.store.getState().login.user.usuario.tipoBodega,
        codigoOficina: props.store.getState().login.user.usuario.codigoOficina,
        codigoEvento: evento,
        codigoExcepcion: excepcion,
        codigoFamilia: '1',
        fechaEvEx: '',
        codigoPersona: props.store.getState().login.user.usuario.usuario,
        numeroOS: props.store.getState().servicio.codigoBarra.codigoBarra.ordenServicio,
        cantidadPiezas: props.store.getState().form.digitacionHubForm.values.piezas,
        observacion: props.store.getState().form.digitacionHubForm.values.observacion,
        codigoPieza: props.store.getState().servicio.codigoBarra.codigoBarra.codigoPieza,
        nombreEmbarcador: props.store.getState().servicio.codigoBarra.codigoBarra.nombreEmbarcador
      }
    };

    props.store.dispatch(actualizaPiezaHub(data));
    return true;
  }

  const limpiarInput = () => {
    props.dispatch(change('digitacionHubForm', 'observacion', ''));
    props.dispatch(change('digitacionHubForm', 'os', ''));
    props.dispatch(change('digitacionHubForm', 'cliente', ''));
    props.dispatch(change('digitacionHubForm', 'fechaPromesa', ''));
    props.dispatch(change('digitacionHubForm', 'osGraba', ''));
    props.dispatch(change('digitacionHubForm', 'piezas', '1'));
    props.dispatch(change('digitacionHubForm', 'cboEvento', ''));
    document.getElementById("os")?.focus();
  }

  return (

    <>
      <CardHeader title='Operación Hub' style={{ background: '#00B4DB', color: '#fff', textAlign: 'center', borderRadius: '6px 6px 0 0' }} />
      <div className="justify-content-center bx-bg-white">
        <Col sm="12">
          <form onSubmit={handleSubmit} noValidate={false} className="mt-20">
            <SelectEvEx
              id="cboEvento"
              name="cboEvento"
              label="Evento / Excepción"
              evento="PU"
              onSelectChange={handleChange}>
            </SelectEvEx>

            <Field
              name="observacion"
              type="text"
              component={ReduxInput}
              label="Observación"
              placeHolder=""
            />

            <Field
              id="os"
              name="os"
              type="text"
              component={ReduxOsFinder}
              label="OS"
              placeHolder=""
              maxLength="28"
              onKeyPress={handleKey}
              onClick={handleClick}
              autoFocus={true}
            />

            <Field
              name="cliente"
              type="text"
              component={ReduxInput}
              label="Cliente"
              placeHolder=""
              disabled={true}
            />

            <Field
              name="fechaPromesa"
              type="text"
              component={ReduxInput}
              label="Fecha Promesa"
              placeHolder=""
              disabled={true}
            />

            <div className="mt-10 mb-10 pt-10 pl-10 bx-border-cian">
              <Field
                name="osGraba"
                type="text"
                component={ReduxInput}
                label="OS"
                placeHolder=""
                style={{ paddingLeft: '8px' }}
                disabled={true}
              />
              <Field
                name="piezas"
                type="text"
                component={ReduxInput}
                label="Piezas"
                placeHolder=""
                style={{ paddingLeft: '8px' }}
              />
            </div>

            <Col sm="12" lg="8" style={{ margin: '0 auto', padding: '0' }} >
              <Button
                block
                color="bx-orange"
                type="button"
                style={{ marginRight: '10px' }}
                onClick={() => grabarButton()}
              >
                Grabar
                &nbsp;
                <FaSave className="button-padding" size={16} />
              </Button>
            </Col>

            <div className="mt-20 background-div">
              <Container fluid={true}>
                <Row xs="2">
                  <FormGroup row={true} className="m-0 justify-content-center">

                    <Col xs="5" lg="3" className="p-0">
                      <Label size="sm">Cantidad OS</Label>
                    </Col>
                    <Col xs="5" lg="3" className="p-0">
                      <h3 className="text-center">{props.store.getState().servicio.totalOS}</h3>
                    </Col>

                  </FormGroup>
                  <FormGroup row={true} className="m-0 justify-content-center">
                    <Col xs="5" lg="3" className="p-0">
                      <Label size="sm">Cantidad Piezas</Label>
                    </Col>
                    <Col xs="5" lg="3" className="p-0">
                      <h3 className="text-center">{props.store.getState().servicio.totalPiezas}</h3>
                    </Col>
                  </FormGroup>
                </Row>
              </Container>
            </div>
            <div className="mt-10 scrollable-x">
              <TableOS />
            </div>
          </form>
        </Col>
      </div>

    </>
  );
}

const mapStateToProps = ({ servicio }: ApplicationState) => ({
  loading: servicio.loading,
  errors: servicio.errors,
  totalOS: servicio.totalOS,
  totalPiezas: servicio.totalPiezas
});

const mapDispatchToProps = (state: any) => {
  return {
    initialValues: {
      evento: 'PU',
      piezas: '1',
      tipo: 'EV'
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm<{}, Props>({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    form: 'digitacionHubForm',
    validate,
    enableReinitialize: true,
  })(DigitacionHubPage));


