import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Servicio } from "../../store/servicios/types";
import { ApplicationState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchRequest } from "../../store/servicios/actions";
import ReactLoading from 'react-loading';

interface PropsFromState {
  loading: boolean;
  data: Servicio;
  errors?: string;
  backgroundTitle?:string
}

interface propsFromDispatch { }

type AllProps = PropsFromState & propsFromDispatch;

const Loading: React.FC<AllProps> = ({
  loading,
  errors,
  data,
  backgroundTitle,
}) => {
  useEffect(() => {
    fetchRequest();
  }, []);

  if (loading) {
  return (
    <div className="overflow-login">
      <ReactLoading type="spokes" className="bx-spin" />
    </div>
  );}else {
    return (
      <div></div>
    );
  }
};

const mapStateToProps = ({ servicio }: ApplicationState) => ({
  loading: servicio.loading,
  errors: servicio.errors,
  data: servicio.data,
  backgroundTitle: servicio.backgroundTitle,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchRequest: () => {
      dispatch(fetchRequest());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Loading);