import React, { useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import '../../App.css';
import { Table } from 'reactstrap';
import { Servicio, createEmptyServicio } from "../../store/servicios/types";
import { fetchRequest } from "../../store/servicios/actions";

interface PropsFromState {
  loading: boolean;
  data: Servicio;
  errors?: string;
}

type AllProps = PropsFromState;

export const TableOS: React.FC<AllProps> = ({
  loading,
  errors,
  data,
  }) => {
    useEffect(() => {
      fetchRequest();
    }, []);
    if (data === undefined) {
     data = createEmptyServicio();
   }

  return (

        <Table hover bordered>
        <thead>
          <tr>
            <th className="w100">OS</th>
            <th className="w85">Cant. Piezas</th>
            <th className="w180">Observación</th>
            <th>Cliente</th>
          </tr>
        </thead>

        <tbody>
              {data.os && data.os.map((each_datarecord, i) => (
                    <tr key={i}>
                      <td>{each_datarecord.os.ordenServicio.numeroOS}</td>
                      <td className="text-center">{each_datarecord.os.ordenServicio.cantidadPiezas}</td>
                      <td>{each_datarecord.os.ordenServicio.observacion}</td>
                      <td>{each_datarecord.os.ordenServicio.nombreEmbarcador}</td>
                    </tr>
                ))
              }    
        </tbody>
        </Table>
      );
};


const mapStateToProps = ({ servicio }: ApplicationState) => ({
  loading: servicio.loading,
  errors: servicio.errors,
  data: servicio.data
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchRequest: () => {
      dispatch(fetchRequest());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TableOS);
