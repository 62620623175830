import { ServicioActionTypes } from "./types";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../index";
import { OsEntity, CodigoBarra, RequestActualizaPiezaHub, ResponseEventoExcepcion } from "../../models";
import { doValidaCodigoBarra, doActualizaPiezaHub, doObtienEventosExcepciones, doToken, doConsultaOrdenDeServicio } from "../../api/os";
import { change } from 'redux-form';

export type AppThunk = ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
>;

export const fetchRequest: AppThunk = () => {

  return (dispatch: Dispatch, getState): Action => {
    const state = getState() as any;
    console.log('fetchRequest: ' + state)
    try {
      return dispatch({
        type: ServicioActionTypes.FETCH_SUCCESS,
        payload: undefined
      });
    } catch (e) {
      return dispatch({
        type: ServicioActionTypes.FETCH_ERROR
      });
    }
  };
};

export const fetchOsMock: AppThunk = (os: string, obs: string, cliente: string, piezas: string) => {

  return (dispatch: Dispatch): Action => {
    const success: ServicioAction = {
      type: ServicioActionTypes.SET_OS,
      payload: {
        ordenServicio: {
          nombreEmbarcador: cliente,
          observacion: obs,
          cantidadPiezas: piezas,
          codigoPieza: '',
          especieValorada: '',
          numeroOS: os
        },
        error: {
          codigo: 0,
          mensaje: ''
        },
      }
    }

    try {
      return dispatch(success);
    } catch (e) {
      return dispatch(success);
    }
  };
};

export interface ServicioAction extends Action<ServicioActionTypes> {
  payload?: OsEntity,
  mensaje?: string,
  totalOS?: number,
  totalPiezas?: number
}

export interface CodigoBarraAction extends Action<ServicioActionTypes> {
  payload?: CodigoBarra,
  mensaje?: string,
  validaOS?: boolean
}

export interface EventosExcepciones extends Action<ServicioActionTypes> {
  payload?: ResponseEventoExcepcion,
  mensaje?: string
}

/////////////////////////////////////////////////////////////

export const validaOS: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<ServicioActionTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<ServicioActionTypes>
>> = (os: string) => {
  console.log('validaOS');

  const getDataOS = (state: any) => {
    var evento: string = '';
    var excepcion: string = '';
  
    if (state.form.digitacionHubForm.values.tipo === 'EV') {
      evento = state.form.digitacionHubForm.values.evento;
      excepcion = '';
    } else {
      evento = '';
      excepcion = state.form.digitacionHubForm.values.evento;
    }

    const data: RequestActualizaPiezaHub = {
      datosPieza: {
        codigoBase: state.login.user.usuario.codigoPosta,
        codigoBodega: state.login.user.usuario.codigoBodega,
        tipoBodega: state.login.user.usuario.tipoBodega,
        codigoOficina: state.login.user.usuario.codigoOficina,
        codigoEvento: evento,
        codigoExcepcion: excepcion,
        codigoFamilia: '1',
        fechaEvEx: '',
        codigoPersona: state.login.user.usuario.usuario,
        numeroOS: state.servicio.codigoBarra.codigoBarra.ordenServicio,
        cantidadPiezas: state.form.digitacionHubForm.values.piezas,
        observacion: state.form.digitacionHubForm.values.observacion,
        codigoPieza: state.servicio.codigoBarra.codigoBarra.codigoPieza,
        nombreEmbarcador: state.servicio.codigoBarra.codigoBarra.nombreEmbarcador
      }
    };

    return data;
  }

  const revisaCambios = async (dispatch: Dispatch, state: any, codigoBarra: CodigoBarra) => {

    const successValida: CodigoBarraAction = {
      type: ServicioActionTypes.SET_VALIDA_OS,
      payload: codigoBarra,
      validaOS: true
    }

    if (state !== undefined) {
      if (state.servicio.loggedin &&
        state.servicio.codigoBarra.codigoBarra.ordenServicio === codigoBarra.codigoBarra.ordenServicio) {
        var piezas = 1 + (!isNaN(state.form.digitacionHubForm.values.piezas) ? Number(state.form.digitacionHubForm.values.piezas) : 0);
        dispatch(change('digitacionHubForm', 'piezas', piezas));

      } else if (state.form.digitacionHubForm.values.osGraba === '' ||
        state.form.digitacionHubForm.values.osGraba === undefined) {
        dispatch(change('digitacionHubForm', 'osGraba', codigoBarra.codigoBarra.ordenServicio));
        dispatch(change('digitacionHubForm', 'cliente', codigoBarra.codigoBarra.nombreEmbarcador));
        dispatch(change('digitacionHubForm', 'fechaPromesa', codigoBarra.codigoBarra.fechaPromesa));
        return dispatch(successValida);

      } else if (state.servicio.loggedin &&
        state.servicio.codigoBarra.codigoBarra.ordenServicio !== codigoBarra.codigoBarra.ordenServicio) {
        var data = getDataOS(state);
        var token = await doToken();
        if (token.access_token !== "") {
          var responseCubicar = await doActualizaPiezaHub(token.access_token, data);

          if (responseCubicar.error.codigo === 0) {

            const successGraba: ServicioAction = {
              type: ServicioActionTypes.FETCH_SUCCESS_OS,
              mensaje: 'OS GRABADA'
            }

            const successTabla: ServicioAction = {
              type: ServicioActionTypes.SET_OS,
              payload: {
                ordenServicio: {
                  nombreEmbarcador: state.servicio.codigoBarra.nombreEmbarcador,
                  observacion: data.datosPieza.observacion,
                  cantidadPiezas: data.datosPieza.cantidadPiezas,
                  codigoPieza: '',
                  especieValorada: '',
                  numeroOS: data.datosPieza.numeroOS
                },
                error: {
                  codigo: 0,
                  mensaje: ''
                },
              }
            }

            dispatch(change('digitacionHubForm', 'osGraba', codigoBarra.codigoBarra.ordenServicio));
            dispatch(change('digitacionHubForm', 'cliente', codigoBarra.codigoBarra.nombreEmbarcador));
            dispatch(change('digitacionHubForm', 'fechaPromesa', codigoBarra.codigoBarra.fechaPromesa));
            dispatch(change('digitacionHubForm', 'piezas', '1'));
            dispatch(successTabla);
            dispatch(successValida);
            return dispatch(successGraba);
          } else {
            const successError: ServicioAction = {
              type: ServicioActionTypes.FETCH_ERROR_OS,
              mensaje: 'ERROR AL GRABAR'
            }
            return dispatch(successError);
          }
        } else {
          const successError: ServicioAction = {
            type: ServicioActionTypes.FETCH_ERROR_OS,
            mensaje: 'ERROR AL OBTENER TOKEN'
          }
          return dispatch(successError);
        }
      }
    }

    return dispatch(successValida);
  }

  return async (dispatch: Dispatch, getState) => {
    const state = getState() as any;
    const loading: ServicioAction = {
      type: ServicioActionTypes.LOADING_VALIDA_OS
    }
    dispatch(loading);

    try {
      var token = await doToken();
      if (token.access_token !== "") {
        var osAux = await doValidaCodigoBarra(token.access_token, os);

        if (osAux.error.codigo === 0 && Number(osAux.codigoBarra.especieValorada) > 0) {
          var consultaOrden = await doConsultaOrdenDeServicio(token.access_token, os);
          console.log("CONSULTA OS: ", consultaOrden);
          if(consultaOrden.respuesta.codigo.toString() === '0') {
            osAux.codigoBarra.nombreEmbarcador = consultaOrden.datosOrdenServicio.nombreEmbarcador;
            osAux.codigoBarra.fechaPromesa = consultaOrden.datosOrdenServicio.fechaPromesa;
          }
          console.log("VALIDA CODIGO BARRA: ", osAux);
          osAux.codigoBarra.ordenServicioUser = os;

          return revisaCambios(dispatch, state, osAux);
          // return dispatch(success);

        } else {
          const error: CodigoBarraAction = {
            type: ServicioActionTypes.FETCH_ERROR_OS,
            payload: osAux,
            mensaje: "Sin datos para OS"
          }

          return dispatch(error);
        }
      } else {
        const error: CodigoBarraAction = {
          type: ServicioActionTypes.FETCH_ERROR_OS,
          mensaje: "Error al Botener Token",
        };

        return dispatch(error);
      }

    } catch (Error) {
      const error: CodigoBarraAction = {
        type: ServicioActionTypes.FETCH_ERROR_OS,
        mensaje: "Error al Validar OS",
      };

      return dispatch(error);
    }
  };

};

export const actualizaPiezaHub: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<ServicioActionTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<ServicioActionTypes>
>> = (os: RequestActualizaPiezaHub) => {
  console.log('actualizaPiezaHub');

  return async (dispatch: Dispatch, getState) => {
    const state = getState() as any;

    const loading: ServicioAction = {
      type: ServicioActionTypes.LOADING
    }

    dispatch(loading);

    try {
      var token = await doToken();
      if (token.access_token !== "") {
        var response = await doActualizaPiezaHub(token.access_token, os);

        if (response.error.codigo === 0 && response.respuesta.codigo === "1") {
          console.log("PARA TABLA", state.servicio.codigoBarra.nombreEmbarcador);
          const successTabla: ServicioAction = {
            type: ServicioActionTypes.SET_OS,
            payload: {
              ordenServicio: {
                nombreEmbarcador: os.datosPieza.nombreEmbarcador,
                observacion: os.datosPieza.observacion,
                cantidadPiezas: os.datosPieza.cantidadPiezas,
                codigoPieza: '',
                especieValorada: '',
                numeroOS: os.datosPieza.numeroOS
              },
              error: {
                codigo: 0,
                mensaje: ''
              },
            }
          }

          dispatch(successTabla);

          const success: ServicioAction = {
            type: ServicioActionTypes.FETCH_SUCCESS_OS,
            mensaje: 'OS GRABADA',
            totalOS: state.totalOS,
            totalPiezas: state.totalPiezas
          }

          return dispatch(success);

        } else {
          const success: ServicioAction = {
            type: ServicioActionTypes.FETCH_ERROR_OS,
            mensaje: 'ERROR AL GRABAR'
          }
          return dispatch(success);
        }
      } else {
        const success: ServicioAction = {
          type: ServicioActionTypes.FETCH_ERROR_OS,
          mensaje: 'ERROR AL OBTENER TOKEN'
        }
        return dispatch(success);
      }
    } catch (Error) {
      console.log('Error al conectarse a la API');
      const err: ServicioAction = {
        type: ServicioActionTypes.FETCH_ERROR_OS,
        mensaje: 'ERROR AL GRABAR'
      }

      return dispatch(err);
    }
  };
};

export const obtieneEventosExcepciones: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<ServicioActionTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<ServicioActionTypes>
>> = () => {
  console.log('obtieneEventosExcepciones');

  return async (dispatch: Dispatch, setState) => {
    const state = setState() as any;

    const loading: ServicioAction = {
      type: ServicioActionTypes.LOADING
    }

    dispatch(loading);

    try {
      var token = await doToken();
      if (token.access_token !== "") {
        var response = await doObtienEventosExcepciones(token.access_token);

        if (response.error.codigo === 0) {

          const success: EventosExcepciones = {
            type: ServicioActionTypes.FETCH_SUCCESS,
            mensaje: '',
            payload: state.evento
          }

          return dispatch(success);

        } else {
          const success: ServicioAction = {
            type: ServicioActionTypes.FETCH_ERROR,
            mensaje: 'ERROR AL OBTENER EVENTOS Y EXCEPCIONES'
          }
          return dispatch(success);
        }
      } else {
        const success: ServicioAction = {
          type: ServicioActionTypes.FETCH_ERROR,
          mensaje: 'ERROR AL OBTENER TOKEN'
        }
        return dispatch(success);
      }
    } catch (Error) {
      console.log('Error al conectarse a la API');
      const err: ServicioAction = {
        type: ServicioActionTypes.FETCH_ERROR_OS,
        mensaje: 'ERROR AL OBTENER EVENTOS Y EXCEPCIONES'
      }

      return dispatch(err);
    }
  };
};

export const Inicializa: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<ServicioActionTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<ServicioActionTypes>
>> = () => {
  console.log('Inicializa');

  return async (dispatch: Dispatch) => {
    const successTabla: ServicioAction = {
      type: ServicioActionTypes.CLEAR_DATA,
      payload: {
        ordenServicio: {
          nombreEmbarcador: '',
          observacion: '',
          cantidadPiezas: '',
          codigoPieza: '',
          especieValorada: '',
          numeroOS: ''
        },
        error: {
          codigo: 0,
          mensaje: ''
        },
      }
    }
    return dispatch(successTabla);
  };

};