import React from 'react';
import { FormGroup, Input, Label, Col, Button } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';

const ReduxOsFinder: React.FC = (field: any) => (
  <FormGroup row={true} className="m-0 justify-content-center">
    <Col xs="5" sm="4" lg="4" className="p-0">
      <Label size="sm" className="bx-label">{field.label}</Label>
    </Col>
    <Col xs="5" sm="6" lg="6" className="pr-0">
      <Input
        {...field.input}
        id={field.id}
        type={field.type}
        placeholder={field.placeHolder}
        max={field.maxDate}
        min={field.minDate}
        maxLength={field.maxLength}
        pattern={field.pattern}
        step={field.step}
        disabled={field.disabled}
        onKeyDown={field.onKeyPress}
        onKeyPress={field.onKeyPress}
        onBlur={field.onBlur}
        className="con-btn"
        size="sm"
        autoFocus={field.autoFocus}
      />
    </Col>
    <Col xs="2" sm="2" lg="2" className="pl-0">
      <Button onClick={field.onClick} color="bx-orange" className="xs">
        <FaSearch className="button-padding" size={13} />
      </Button>
    </Col>
  </FormGroup>
);

export default ReduxOsFinder;
