import React from 'react';
import createStyles from "@material-ui/styles/createStyles";
import TextField from "@material-ui/core/TextField";
import {Button} from 'reactstrap'
import { LoginEntity, createEmptyLogin } from "../../models";
import { connect } from "react-redux";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
}

// https://material-ui.com/styles/api/#makestyles-styles-options-hook
const useFormStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formInput: {
      textTransform: "uppercase"
    }
  })
);

export const LoginComponent: React.FC<Props> = (props: any) => {
  const { onLogin } = props;
  const [loginInfo, setLoginInfo] = React.useState<LoginEntity>(
    createEmptyLogin()
  );
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const classes = useFormStyles();

  const onTexFieldChange = (fieldId: string) => (e: any) => {
    setLoginInfo({
      ...loginInfo,
      [fieldId]: e.target.value.toString().toUpperCase(),
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <form>
      <div className={classes.formContainer}>
        <TextField
          id="bxUsuario"
          label="Usuario"
          margin="normal"
          value={loginInfo.login}
          onChange={onTexFieldChange("login")}
        />

        <FormControl>
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
        <Input
          id="bxPass"
          type={values.showPassword ? 'text' : 'password'}
          value={loginInfo.password}
          onChange={onTexFieldChange("password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        </FormControl>
        <Button
          block
          variant="contained"
          color="bx-orange"
          style={{ marginTop: '10px', fontWeight: 'bold' }}
          onClick={() => onLogin(loginInfo)}>
          Ingresar
        </Button>

      </div>

    </form>
  );
};


export default connect(null)(LoginComponent);