
export const tokenUsuario = "cf4112bfe5fc1076026c81ead8650d8d";
export const codigoUsuario = 1666;
export const granttype = "client_credentials";
export const clientid = "private-cli";
export const familia = 0;
//export const urlBaseHub = "https://endpoint.bluex.cl";

/**********************************************************************************************************/
/**                                     CONSTANTES QA                                                    **/
/**********************************************************************************************************/
/*export const urlBaseHub = "https://qaapigw.bluex.cl/OperacionHubREST";
export const apikey = "wM0tGzyyrhBvQ5b6lqwBYLjIywYpM906";
export const clientsecret = "3ab47c2d-91c4-4afc-a691-7dd69de35ac2";
export const urlToken = "https://qa.sso.bluex.cl/auth/realms/salesforce/protocol/openid-connect/token";*/

/**********************************************************************************************************/
/**                                     CONSTANTES PROD                                                  **/
/**********************************************************************************************************/
export const urlBaseHub = "https://apigw.bluex.cl/OperacionHubREST";
export const apikey = "sfBdNaBWrErYpwTT2k9alwIFbyCcm5eL";
export const clientsecret = "78a38e92-79ef-42c1-81ea-1300bb01de14";
export const urlToken = "https://sso.bluex.cl/auth/realms/salesforce/protocol/openid-connect/token";