import {LoggedUser} from './../../api/login'

export enum ValidaUsuarioTypes {
    VALIDA_USUARIO ="@@validaUsuario/VALIDA_USUARIO",
    AFTER_LOGIN = "@@validaUsuario/AFTER_LOGIN",
    LOGOUT = "@@validaUsuario/LOGOUT",
    VALIDA_USUARIO_LOADING = "@@validaUsuario/VALIDA_USUARIO_LOADING",
    LOGIN_ERROR = "@@validaUsuario/LOGIN_ERROR",
    CLEAR_MESSAGE = "@@validaUsuario/CLEAR_MESSAGE"
}
export interface ValidaUsuarioState {
    readonly loggedin: boolean,
    readonly user?: LoggedUser,
    readonly loading?: boolean,
    readonly errors?: string
}

export const createEmptyValidaUsuarioState = (): ValidaUsuarioState => ({
    loggedin:false,
    loading: false
});
