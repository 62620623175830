import { Store, createStore, applyMiddleware } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";

import { History } from "history";

import { ApplicationState, createRootReducer } from "./store";


export default function configureStore(
  history: History,
  initialState: ApplicationState
): Store<ApplicationState> {
  const persistConfig = {
    key: 'operacioneshub.bluex',
    storage,
    version: 1,
    debug: true
  };
  const persistedReducer = persistReducer(persistConfig, createRootReducer(history));
  const store = createStore(
    persistedReducer, 
    applyMiddleware(routerMiddleware(history), thunk));

  return store;
}