import * as React from "react";
import { LoginEntity } from "../../models";
import { LoginComponent } from "./login.component";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import createStyles from "@material-ui/styles/createStyles";
import makeStyles from "@material-ui/styles/makeStyles";
import { NotificationComponent } from "../../common";
import { Store, Action } from "redux";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { doLogin, logout } from "../../store/login/actions";
import { ToastContainer, toast } from 'react-toastify';
import { LoginTypes } from "../../store/login/types";
import Loading from './loading';

const useFormStyles = makeStyles((theme) =>
  createStyles({
    card: {
      maxWidth: 400,
      margin: "30px auto",
    },
  })
);

interface MainProps {
  store: Store<ApplicationState>,
  logout?: boolean
}

interface State {
  message: string;
}

export const LoginContainer: React.FC<MainProps> = (props: any) => {
  const [isShowAlert, setShowAlert] = React.useState(false);
  const classes = useFormStyles();

  const handleLogin = (login: LoginEntity) => {
    if (login.login && login.login.length > 0 && login.password && login.password.length > 0) {
      console.log('Do login');
      props.store.getState().servicio.loading = true;
      props.store.dispatch(doLogin(login));
    } else {
      console.log('Vacio');
    }
  };


  if (props.logout) {
    console.log("LOGOUT: ", props.logout);
    props.store.dispatch(logout());
  }

  props.store.subscribe(() => {
    if (props.store.getState().login.errors !== undefined) {
      toast.error(props.store.getState().login.errors);

      const error: Action<String> = {
        type: LoginTypes.CLEAR_MESSAGE
      }
      props.store.getState().servicio.loading = false;
      props.store.dispatch(error);
    }

  });

  return (
    <>
      <div>&nbsp;<ToastContainer autoClose={2000} /></div>
      <Card className={classes.card}>
        <Loading />
        <CardHeader title="Bienvenido" style={{ background: '#00B4DB', color: '#fff', textAlign: 'center', borderRadius: '6px 6px 0 0' }} />
        <CardContent>
          <LoginComponent {...props} onLogin={handleLogin} />
          <NotificationComponent
            show={isShowAlert}
            message="Operación Hub"
            onClose={() => setShowAlert(false)}
          />
        </CardContent>
      </Card>
    </>
  );
};
export default connect(null)(LoginContainer);