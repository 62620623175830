import { Reducer } from "redux";
import { ServicioActionTypes, ServicioState, createEmptyServicio, OsEntityRubber } from "./types";
import { Guid } from "guid-typescript";

function isNumber(value: string | number): boolean {
  return ((value != null) &&
    (value !== '') &&
    !isNaN(Number(value.toString())));
}

export const initialState: ServicioState = {
  data: createEmptyServicio(),
  errors: undefined,
  loading: false,
  codigoBarra: undefined,
  totalOS: 0,
  totalPiezas: 0,
  eventos: undefined
};

const reducer: Reducer<ServicioState> = (state = initialState, action) => {
  var isOS = state.codigoBarra !== undefined ? true : false;

  switch (action.type) {
    case ServicioActionTypes.LOADING: {
      console.log('LOADING');
      return {
        loading: true,
        data: state.data
      }
    }
    case ServicioActionTypes.FETCH_REQUEST_OS: {
      console.log('FETCH_REQUEST_OS');
      var auxOs: OsEntityRubber[] = state.data.os;
      return {
        data: {
          os: auxOs
        }
        , loading: false
      };
    }
    case ServicioActionTypes.FETCH_SUCCESS: {
      console.log("FETCH_SUCCESS: ", action.mensaje);
      var auxOsSet: OsEntityRubber[] = state.data.os;
      return {
        loading: false,
        data: {
          osSearch: 0,
          os: auxOsSet
        },
        codigoBarra: state.codigoBarra,
        message: ' OS INGRESADA'
      }
    }

    case ServicioActionTypes.CLEAR_MESSAGE: {
      console.log('CLEAR_MESSAGE');
      return {
        loading: false,
        data: state.data,
        message: undefined
      }
    }
    case ServicioActionTypes.SET_OS: {
      console.log('SET_OS: ', action.payload);
      var piezas = 0;
      var os = 0;

      if (state.data !== undefined) {
        const nuevo: OsEntityRubber = {
          id: Guid.create().toString(),
          os: action.payload
        }
        var auxOsSet1: OsEntityRubber[] = [];
        auxOsSet1.push(nuevo);
        os = os + 1;
        piezas = piezas + (isNumber(nuevo.os.ordenServicio.cantidadPiezas) ? Number(nuevo.os.ordenServicio.cantidadPiezas) : 0);

        // state.data.os.map((each_datarecord, i) => (
        state.data.os.forEach((each_datarecord) => {
          os = os + 1;
          piezas = piezas + (isNumber(each_datarecord.os.ordenServicio.cantidadPiezas) ? Number(each_datarecord.os.ordenServicio.cantidadPiezas) : 0);
          auxOsSet1.push(each_datarecord);

        });

        return {
          loading: false,
          data: {
            osSearch: '',
            os: auxOsSet1
          },
          codigoBarra: state.codigoBarra,
          totalOS: os,
          totalPiezas: piezas,
        }
      } else {
        var auxOsSet2: OsEntityRubber[] = [];
        const nuevo: OsEntityRubber = {
          id: Guid.create().toString(),
          os: action.payload
        }
        auxOsSet2.push(nuevo);
        return {
          loading: false,
          data: {
            osSearch: '',
            os: auxOsSet2
          }
        }
      }

    }
    case ServicioActionTypes.CLEAR_DATA: {
      console.log("CLEAR_DATA :", action.payload);
      var data1: OsEntityRubber[] = [];
      return {
        loading: false,
        data: {
          osSearch: 0,
          os: data1
        },
        message: undefined,
      }
    }

    /////////////////////////////////////////
    case ServicioActionTypes.FETCH_ERROR: {
      console.log('FETCH_ERROR: ', action);
      return {
        loading: false,
        loggedin: isOS,
        data: state.data,
        codigoBarra: state.codigoBarra,
        totalOS: state.totalOS,
        totalPiezas: state.totalPiezas,
        errors: action.mensaje
      };
    }

    case ServicioActionTypes.FETCH_ERROR_OS: {
      console.log('FETCH_ERROR_OS: ', action);
      return {
        loading: false,
        loggedin: isOS,
        backgroundTitle: '#f44336', //#f44336 #aa2e25
        data: state.data,
        codigoBarra: state.codigoBarra,
        totalOS: state.totalOS,
        totalPiezas: state.totalPiezas,
        errors: action.mensaje,
        errorOS: true
      };
    }

    case ServicioActionTypes.CLEAR_MESSAGE_OS: {
      console.log('CLEAR_MESSAGE_OS');
      return {
        loading: false,
        loggedin: isOS,
        backgroundTitle: state.backgroundTitle,
        data: state.data,
        codigoBarra: state.codigoBarra,
        totalOS: state.totalOS,
        totalPiezas: state.totalPiezas,
        message: undefined,
        validaOS: undefined,
      }
    }

    case ServicioActionTypes.FETCH_SUCCESS_OS: {
      console.log("FETCH_SUCCESS_OS: ", action.mensaje);
      var auxOsSet3: OsEntityRubber[] = state.data.os;
      return {
        loading: false,
        loggedin: isOS,
        data: {
          osSearch: 0,
          os: auxOsSet3
        },
        codigoBarra: state.codigoBarra,
        totalOS: state.totalOS,
        totalPiezas: state.totalPiezas,
        message: ' OS INGRESADA'
      }
    }

    case ServicioActionTypes.LOADING_VALIDA_OS: {
      console.log('LOADING_VALIDA_OS');
      return {
        loading: true,
        loggedin: false,
        data: state.data,
        codigoBarra: state.codigoBarra,
        totalOS: state.totalOS,
        totalPiezas: state.totalPiezas,
      }
    }
    case ServicioActionTypes.SET_VALIDA_OS: {
      console.log('SET_VALIDA_OS: ', action.payload);
      return {
        loading: false,
        loggedin: true,
        backgroundTitle: undefined,
        data: {
          osSearch: 0,
          os: state.data.os
        },
        codigoBarra: action.payload,
        totalOS: state.totalOS,
        totalPiezas: state.totalPiezas,
        validaOS: action.validaOS,
      }
    }

    case ServicioActionTypes.GET_EVENTOS_EXCEPCIONES: {
      console.log('GET_EVENTOS_EXCEPCIONES: ', action.payload);
      return {
        loading: false,
        data: state.data,
        eventos: state.eventos
      }
    }

    default: {
      return state;
    }
  }
};
export { reducer as ServicioReducer };