import { Reducer } from "redux";
import { ValidaUsuarioState, ValidaUsuarioTypes } from "./types";

export const initialState: ValidaUsuarioState = {
    loggedin: false,
    loading: false
};

const reducer: Reducer<ValidaUsuarioState> = (state = initialState, action) => {
    switch (action.type) {
        case ValidaUsuarioTypes.LOGOUT: {
            console.log('LOGOUT ');
            return {
                loggedin: false,
                user: undefined,
                loading: false
            }
        }
        case ValidaUsuarioTypes.CLEAR_MESSAGE: {
            console.log('CLEAR_MESSAGE ');
            return {
                loggedin: false,
                user: undefined,
                loading: false,
                errors: undefined
            }
        }
        case ValidaUsuarioTypes.LOGIN_ERROR: {
            console.log('LOGIN_ERROR ', action.error);
            return {
                loggedin: false,
                user: undefined,
                loading: false,
                errors: action.error
            }
        }
        case ValidaUsuarioTypes.VALIDA_USUARIO: {
            console.log('VALIDA_USUARIO ');
            return {
                loggedin: true,
                user: action.payload,
                loading: false
            }
        }
        case ValidaUsuarioTypes.VALIDA_USUARIO_LOADING: {
            console.log('VALIDA USUARIO LOADING');
            return {
                loggedin: false,
                loading: true
            }
        }
        case ValidaUsuarioTypes.AFTER_LOGIN: {
            console.log('LAFTER LOGING');
            return {
                loggedin: true,
                user: state.user,
                loading: false
            }
        }
        default: {
            return state;
        }
    }
};
export { reducer as ValidaUsuarioReducer };