import React from "react";
import { Route, Switch } from "react-router-dom";
import { LoginContainer } from "./components/login/login.container";
import NavbarMenu from "./components/Navbar";
import NavbarLogin from "./components/NavbarLogin";
import { Individual } from "./components/individual/individual";
import { DigitacionHub } from "./components/digitacionHub/digitacionHub";
import { Store } from "redux";
import { ApplicationState } from "./store";
import { connect } from "react-redux";

interface MainProps {
  store: Store<ApplicationState>;
}

const Routes: React.SFC<MainProps> = ({ store },props:any) => {
  console.log("STORE 2" , store.getState());
  return (
    <div>
      <Switch>
      <Route
          exact
          path="/"
          render={() => {
            return (
            <NavbarLogin >
              <LoginContainer store={store} logout={false}/>
            </NavbarLogin>
          );}}
        />
      <Route
          exact
          path="/logout"
          render={() => {
            return (
            <NavbarLogin >
              <LoginContainer store={store} logout={true}/>
            </NavbarLogin>
          );}}
        />
        <Route
          exact
          path="/login"
          render={() => (
            <NavbarLogin >
              <LoginContainer store={store}/>
            </NavbarLogin>
          )}
        />
        
        <Route
          path="/individual"
          render={() => (
            <NavbarMenu>
              <Individual store={store}/>
            </NavbarMenu>
          )}
        />
          
      <Route
          path="/digitacionHub"
          render={() => (
            <NavbarMenu>
              <DigitacionHub store={store} />
            </NavbarMenu>
          )}
        />

      </Switch>
    </div>
  );
}

export default connect(null)(Routes);
